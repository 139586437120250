import 'element-closest';
import '../utilities/matches';

const main = document.getElementById( 'main' );

function removeHeight() {
	this.style.height = null;
	this.removeEventListener( 'transitionend', this.transitionendEvent );
}

function collapseItem( element ) {
	const item = element;
	const itemHeight = item.scrollHeight;
	const itemTransition = item.style.transition;
	item.style.transition = '';
	requestAnimationFrame( () => {
		item.style.height = `${itemHeight}px`;
		item.style.transition = itemTransition;
		requestAnimationFrame( () => {
			item.style.height = '0px';
			item.setAttribute( 'aria-hidden', true );
		} );
	} );
}

function expandItem( element ) {
	const item = element;
	const itemHeight = item.scrollHeight;
	item.style.height = `${itemHeight}px`;
	item.transitionendEvent = removeHeight.bind( item );
	item.addEventListener( 'transitionend', item.transitionendEvent );
	item.setAttribute( 'aria-hidden', false );
}

function toggleItem() {
	const target = document.getElementById( this.getAttribute( 'aria-controls' ) );
	const isCollapsed = this.getAttribute( 'aria-expanded' ) === 'false';
	this.setAttribute( 'aria-expanded', isCollapsed );

	if ( isCollapsed ) {
		expandItem( target );
	} else {
		collapseItem( target );
	}
}

function handleIndividualOrGroup() {
	const group = this.closest( '.collapsibleGroup' );

	if ( group ) {
		const openItem = group.querySelector( '[aria-expanded="true"]' );
		if ( openItem && openItem !== this ) {
			toggleItem.call( openItem );
			window.setTimeout( () => {
				toggleItem.call( this );
			}, 300 );
		} else {
			toggleItem.call( this );
		}
	} else {
		toggleItem.call( this );
	}
}

function initEvents() {
	main.addEventListener( 'click', ( e ) => {
		const collapsible = e.target.closest( '.collapsible' );
		if ( collapsible ) {
			handleIndividualOrGroup.call( collapsible );
		}
	} );
}

function init() {
	initEvents();
}

export default {
	init,
};
