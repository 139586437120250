import 'classlist-polyfill';
import Flickity from 'flickity';

const pageHero = document.getElementById( 'pageHero' );
const flkty = [];

function initFlickity( el ) {
	let timeout = el.getAttribute( 'data-timeout' ) || 0;
	timeout = parseInt( timeout, 10 );

	return new Flickity( el, {
		cellSelector: '.pageHero-slide',
		autoPlay: timeout,
		pageDots: true,
		prevNextButtons: true,
		setGallerySize: false,
		wrapAround: true,
		arrowShape: {
			x0: 10, x1: 60, y1: 50, x2: 65, y2: 45, x3: 20,
		},
	} );
}

function init() {
	if ( pageHero ) {
		flkty.push( initFlickity( pageHero ) );
	}
}

export default {
	init,
};
