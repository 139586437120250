import 'element-closest';
import '../utilities/matches';

function updateLocationOrOpenNewTab( e, link ) {
	if ( link ) {
		e.preventDefault();
		const isExternal = link.host !== window.location.host;

		if ( isExternal || e.ctrlKey || e.metaKey ) {
			const newWindow = window.open();
			newWindow.opener = null;
			newWindow.location = link.href;
		} else {
			window.location = link.href;
		}
	}
}

function makeBlocksClickable( e ) {
	const blockLink = e.target.closest( '.blockLink' );

	if ( blockLink && !e.target.matches( 'a' ) ) {
		updateLocationOrOpenNewTab( e, blockLink.querySelector( '.blockLink-url' ) );
	}
}

function init() {
	document.addEventListener( 'click', makeBlocksClickable );
}

export default {
	init,
};
