import 'classlist-polyfill';
import 'element-closest';

const main = document.getElementById( 'main' );
const collapsible = main.querySelectorAll( '.js-makeCollapsible' );

function addAriaAttributes( button, index ) {
	const id = `collapsible-${index}`;
	const target = button.nextElementSibling;
	button.setAttribute( 'aria-role', 'button' );
	button.setAttribute( 'aria-expanded', false );
	button.setAttribute( 'aria-controls', id );
	target.setAttribute( 'id', id );
	target.setAttribute( 'aria-hidden', true );
}

function removeVerticalCentering() {
	const targetClass = 'row--alignCenter';
	const centeredRow = this.closest( `.${targetClass}` );
	if ( centeredRow ) {
		centeredRow.classList.remove( targetClass );
	}
}

function makeCollapsible() {
	const collapsibleCount = collapsible.length;
	for ( let i = 0; i < collapsibleCount; i += 1 ) {
		addAriaAttributes( collapsible[i], i + 1 );
		collapsible[i].classList.remove( 'js-makeCollapsible' );
		collapsible[i].classList.add( 'collapsible' );
		removeVerticalCentering.call( collapsible[i] );
	}
}

function init() {
	makeCollapsible();
}

export default {
	init,
};
