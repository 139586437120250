/**
 * Reorder the profile flexbox items.
 *
 * Allows full width bios to be exapanded/displayed within a grid of regular flexbox items/columns.
 */

import debounce from 'lodash.debounce';
import Breakpoint from '../utilities/Breakpoint';

const profileSections = Array.from( document.querySelectorAll( '.js-profileOrder' ) ).map( x => x.querySelectorAll( ':scope > div' ) );

let cardCounter = 0;
let columnCounter = 0;
let bioCounter = 0;
let bioIncrement = 0;
let columns = 0;

function orderCards( el ) {
	const card = el;

	// Skip a number to allow the bios to be placed after each row.
	if ( cardCounter % bioIncrement === 0 ) {
		cardCounter += 1;
	}
	card.style.order = `${cardCounter}`;
	cardCounter += 1;
}

function orderBios( el ) {
	const bio = el;
	bio.style.order = `${bioCounter}`;

	// Increment the bioCounter by the number of columns.
	// when the max number of columns is reached.
	if ( columnCounter === columns ) {
		columnCounter = 1;
		bioCounter += bioIncrement;
	} else {
		columnCounter += 1;
	}
}

function initOrder() {
	for ( let j = 0; j < profileSections.length; j += 1 ) {
		const items = profileSections[j];

		// Reset the counters.
		cardCounter = 1;
		columnCounter = 1;

		// Reset the number of columns and bio increment according to breakpoint.
		bioIncrement = ( Breakpoint.get() === 'tablet' ) ? 3 : 4;
		columns = ( ( Breakpoint.get() === 'tablet' ) ? 2 : 3 );
		bioCounter = bioIncrement;

		for ( let i = 0; i < items.length; i += 1 ) {
			if ( i % 2 === 0 ) {
				orderCards( items[i] );
			} else {
				orderBios( items[i] );
			}
		}
	}
}

function removeOrder() {
	for ( let j = 0; j < profileSections.length; j += 1 ) {
		const items = profileSections[j];
		for ( let i = 0; i < items.length; i += 1 ) {
			items[i].style.order = null;
		}
	}
}

function initColumns() {
	if ( Breakpoint.get() === 'mobile' ) {
		removeOrder();
		return;
	}
	initOrder();
}

function initEvents() {
	window.addEventListener( 'resize', debounce( ( ) => {
		initColumns();
	}, 200 ) );
}

function init() {
	initColumns();
	initEvents();
}

export default {
	init,
};
