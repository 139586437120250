import MapPin from './MapPin';
import MapStyle from './MapStyle';

const maps = document.querySelectorAll( '.map' );


function getCoords() {
	return {
		lat: parseFloat( this.getAttribute( 'data-lat' ) ),
		lng: parseFloat( this.getAttribute( 'data-lng' ) ),
	};
}

function renderMap( mapContainer ) {
	const pinContent = mapContainer.querySelector( '.mapPin-content' );
	const coords = new window.google.maps.LatLng( getCoords.apply( mapContainer ) );
	const args = {
		center: coords,
		fullscreenControl: false,
		mapTypeControl: false,
		mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		panControl: false,
		scrollwheel: false,
		styles: MapStyle,
		zoomControl: true,
		zoom: 16,
		zoomControlOptions: {
			position: window.google.maps.ControlPosition.RIGHT_TOP,
		},
		streetViewControlOptions: {
			position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
		},
	};

	const map = new window.google.maps.Map( mapContainer, args );
	const pin = new MapPin.Pin( coords, pinContent );
	pin.setMap( map );
}

window.initSoftelMaps = () => {
	MapPin.subClassOverlayView();

	for ( let i = 0; i < maps.length; i += 1 ) {
		renderMap( maps[i] );
	}
};

function googleMapsAsyncLoad() {
	const script = document.createElement( 'script' );
	script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${window.softelMapsKey}&callback=initSoftelMaps`;
	document.body.appendChild( script );
}

function init() {
	if ( maps && window.softelMapsKey ) {
		googleMapsAsyncLoad();
	}
}

export default {
	init,
};
