import 'classlist-polyfill';
import Breakpoint from '../utilities/Breakpoint';
import NavMain from './NavMain';

const navbar = document.getElementById( 'navbar' );
const boundry = navbar.offsetHeight;
const inertia = 2;
let scrollY = 0;
let prevScrollY = 0;
let ticking = false;

function handleScrollUp() {
	prevScrollY = scrollY;
	const position = window.scrollY || window.pageYOffset;
	scrollY = position + inertia;

	if ( scrollY > boundry ) {
		document.body.classList.add( 'scrolled' );
		document.body.classList.toggle( 'scrollDown', scrollY > prevScrollY );
		if ( scrollY > prevScrollY && Breakpoint.get() !== 'mobile' ) {
			NavMain.closeOpenItem( false );
		}
	} else {
		document.body.classList.remove( 'scrollDown', 'scrolled' );
	}

	ticking = false;
}

function requestTick() {
	if ( !ticking ) {
		window.requestAnimationFrame( handleScrollUp );
		ticking = true;
	}
}

function init() {
	window.addEventListener( 'scroll', requestTick );
}

export default {
	init,
};
