import 'focus-visible';
import svg4everybody from 'svg4everybody';
import BlockLink from './components/BlockLink';
import Collapsible from './components/Collapsible';
import GoogleMaps from './components/GoogleMaps';
import MakeCollapsible from './components/MakeCollapsible';
import Navbar from './components/Navbar';
import NavMain from './components/NavMain';
import PageHero from './components/PageHero';
import ProfileOrder from './components/ProfileOrder';
import ServiceWorker from './utilities/ServiceWorker';

BlockLink.init();
Collapsible.init();
GoogleMaps.init();
MakeCollapsible.init();
Navbar.init();
NavMain.init();
PageHero.init();
ProfileOrder.init();
svg4everybody();
ServiceWorker.register();
