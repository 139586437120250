export default function toggleItem( callback ) {
	const targetId = this.getAttribute( 'aria-controls' );
	const target = document.getElementById( targetId );
	const isExpanded = this.getAttribute( 'aria-expanded' ) === 'false';
	const allToggles = document.querySelectorAll( `[aria-controls="${targetId}"]` );

	for ( let i = 0; i < allToggles.length; i += 1 ) {
		allToggles[i].setAttribute( 'aria-expanded', isExpanded );
	}

	target.setAttribute( 'aria-hidden', !isExpanded );

	if ( typeof callback === 'function' ) {
		callback.call( target, isExpanded );
	}
}
