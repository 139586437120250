import 'classlist-polyfill';
import ScrollLock from 'body-scroll-lock';
import toggleExpandable from './Expandable';
import Breakpoint from '../utilities/Breakpoint';

const navbarToggle = document.getElementById( 'navbar-burger' );
const navMain = document.getElementById( 'navMain' );
const submenuToggle = navMain.querySelectorAll( '.navMain-parent > a' );
const overlay = document.createElement( 'div' );

function addAriaAttributes( anchor, index ) {
	const id = `navMain-submenu-${index}`;
	const target = anchor.nextElementSibling;
	anchor.setAttribute( 'aria-expanded', false );
	anchor.setAttribute( 'aria-controls', id );
	target.setAttribute( 'id', id );
	target.setAttribute( 'aria-hidden', true );
}

function closeOpenItem( button ) {
	const openItem = navMain.querySelector( '[aria-expanded="true"]' );
	if ( openItem && openItem !== button ) {
		toggleExpandable.call( openItem );
		document.body.classList.remove( 'navbar-is-open' );
	}
}

function toggleDesktopItem( button ) {
	closeOpenItem( button );
	toggleExpandable.call( button );
	document.body.classList.toggle( 'navbar-is-open' );
}

function toggleBodyScroll( isHidden ) {
	if ( isHidden ) {
		ScrollLock.disableBodyScroll( this );
		document.body.classList.add( 'navbar-is-open' );
	} else {
		ScrollLock.enableBodyScroll( this );
		document.body.classList.remove( 'navbar-is-open' );
	}
}

function handleBreakpointEvents( e ) {
	e.preventDefault();

	if ( Breakpoint.get() === 'mobile' ) {
		toggleExpandable.call( this );
	} else {
		toggleDesktopItem( this );
	}
}

function setupOverlay() {
	overlay.classList.add( 'overlay' );
	document.body.appendChild( overlay );
}

function setupSubmenus() {
	const submenuCount = submenuToggle.length;
	for ( let i = 0; i < submenuCount; i += 1 ) {
		addAriaAttributes( submenuToggle[i], i + 1 );
		submenuToggle[i].addEventListener( 'click', handleBreakpointEvents.bind( submenuToggle[i] ) );
	}
}

function initEvents() {
	navbarToggle.addEventListener( 'click', () => toggleExpandable.call( navbarToggle, toggleBodyScroll ) );
	overlay.addEventListener( 'click', () => {
		closeOpenItem( null );
	} );
}

function init() {
	setupOverlay();
	setupSubmenus();
	initEvents();
}

export default {
	init,
	closeOpenItem,
};
