const widths = {
	tabletPortrait: 768,
	tabletLandscape: 992,
	desktop: 1200,
};

function get() {
	const width = window.innerWidth || document.documentElement.clientWidth;
	let breakpoint = '';

	if ( width < widths.tabletPortrait ) {
		breakpoint = 'mobile';
	} else if ( width >= widths.tabletPortrait && width < widths.desktop ) {
		breakpoint = 'tablet';
	} else {
		breakpoint = 'desktop';
	}

	return breakpoint;
}

export default {
	widths,
	get,
};
